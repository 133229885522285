import * as Sentry from "@sentry/vue";

import {isString} from "../validators";

import type {
    ResponseErrorType,
    ErrorErrorType,
    MessageErrorType,
    LogErrorMetaType
} from "@songfinch/types";

export const isResponseErrorType = (error: unknown): error is ResponseErrorType => {
    return (!!error && !!(error as ResponseErrorType)?.response?.data?.error);
};

export const isErrorErrorType = (error: unknown): error is ErrorErrorType => {
    return (!!error && !!(error as ErrorErrorType)?.error);
};

export const isMessageErrorType = (error: unknown): error is MessageErrorType => {
    return (!!error && !!(error as MessageErrorType)?.message);
};

export const normalizeError = (error: unknown, defaultMessage = ""): string => {
    if (isString(error)) {
        return error;
    } else if (isResponseErrorType(error)) {
        return error.response.data.error.message || error.response.data.error as string;
    } else if (isErrorErrorType(error)) {
        return error.error;
    } else if (isMessageErrorType(error) && error.message === "Network Error") {
        return "Network Error. Please check your internet connection or contact us at support@songfinch.com.";
    } else {
        return defaultMessage || "Sorry, something went wrong. Please try again or contact us at support@songfinch.com.";
    }
};

export const logError = (error: unknown, meta?: LogErrorMetaType) => { 
    Sentry.withScope(scope => {
        scope.setFingerprint([window.location.pathname]);
        if (meta?.userType) {
            // TODO: set this on authentication;
            scope.setTag("userType", meta?.userType);
        }

        // Ensure the error is an instance of Error
        let capturedError: Error;
        if (error instanceof Error) {
            capturedError = error;
        } else if (typeof error === "string") {
            capturedError = new Error(error);
        } else {
            capturedError = new Error("Unknown error");
            scope.setExtra("errorDetails", error); // Capture the raw error details in Sentry
        }

        Sentry.captureException(capturedError, meta?.extra);
    });
};